import { useGoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getAssetUrl } from '../../../helpers/general';

const googleImageUrl = getAssetUrl("google.png");

const GoogleLoginButton = (props) => {
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            const token = codeResponse.access_token;
            // return token;
            props.onSuccess(token);
        },
        onError: (error) => {
            console.log("Error by Google:", error);
            // return error;
            props.onError(error)
        },
    });

    return (
        <button data-cgully-client-id={process.env.GOOGLE_CLIENT_ID} className="bg-[#ECECEC] flex justify-center items-center gap-2 rounded-xl py-3" onClick={login}>
            <img src={googleImageUrl} alt="google" className="h-6 w-6 " /> <span className="font-semibold">Google</span>
        </button>
    );
};

const Wrapper = (props) => {
    return (
        <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
            <GoogleLoginButton {...props} />
        </GoogleOAuthProvider>
    )
}

export default Wrapper;